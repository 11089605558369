import { useRouteError } from 'react-router-dom';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const error = useRouteError() as unknown as {
    statusText: string;
    message: string;
  };
  // eslint-disable-next-line no-console
  console.error(error);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Oops!</h1>
        <p className={styles.caption}>
          Sorry, an unexpected error has occurred.
        </p>
        <span className={styles.error}>
          <i>{error.statusText || error.message}</i>
        </span>
      </div>
    </div>
  );
};

export default NotFound;
