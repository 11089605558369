import { FC } from 'react';

import styles from './Footer.module.scss';

const year = new Date().getFullYear();

const Footer: FC = () => (
  <div className={styles.footer}>
    <span>Copyright © {year}</span>
    <span className={styles.name}>Jeanaica Suplido</span>
  </div>
);

export default Footer;
