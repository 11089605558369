import { FC } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import styles from './Icon.module.scss';

type Props = {
  icon: IconDefinition;
  className?: string;
};

const Icon: FC<Props> = ({ icon, className }) => (
  <div className={clsx(styles.icons, className)}>
    <FontAwesomeIcon icon={icon} />
  </div>
);

export default Icon;
