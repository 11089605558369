import { Outlet } from 'react-router-dom';
import Content from 'common/content/Content';
import Footer from 'common/footer/Footer';
import Header from 'common/header/Header';

function Root() {
  return (
    <>
      <Header />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </>
  );
}

export default Root;
