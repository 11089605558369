import { FC } from 'react';
import IconLink from 'common/iconLink/IconLink';

import socials from './socials';

import styles from './About.module.scss';

const About: FC = () => (
  <div className={styles.about}>
    <div className={styles.inner}>
      <img
        className={styles.avatar}
        src={`${process.env.PUBLIC_URL}/github.jpeg`}
      />
      <div className={styles.info}>
        <p className={styles.text}>
          I’m Jeanaica, a work in progress. Join me in my journey to good mental
          health, to building a family, building apps, and knowing God.
        </p>
        <div className={styles.contact}>
          {socials.map(({ icon, link, label }) => (
            <IconLink
              key={label}
              icon={icon}
              link={link}>
              <span>{label}</span>
            </IconLink>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default About;
