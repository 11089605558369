import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';

import menuItems from './menu.json';

import styles from './Menu.module.scss';

type Props = {
  isExpanded: boolean;
  isMobile: boolean;
};

const Menu: FC<Props> = ({ isExpanded, isMobile }) => {
  const navigate = useNavigate();

  return (
    <div
      className={clsx(styles.menu, {
        [styles.expanded]: isExpanded && isMobile,
        [styles.unexpanded]: isMobile && !isExpanded,
      })}>
      <div className={styles.inner}>
        {menuItems.map(({ name, label, route }) => (
          <button
            onClick={() => navigate(route)}
            className={styles.button}
            key={name}>
            <span>{label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Menu;
