import { createBrowserRouter } from 'react-router-dom';
import Introduction from 'articles/Introduction';
import Second from 'articles/Second';
import Home from 'pages/home/Home';
import NotFound from 'pages/notFound/NotFound';

import Root from './Root';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        errorElement: <NotFound />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          // {
          //   path: '/article/hello-world',
          //   element: <Introduction />,
          // },
          // {
          //   path: '/article/my-story-so-far',
          //   element: <Second />,
          // },
        ],
      },
    ],
  },
]);

export default router;
