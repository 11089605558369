import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './Link.module.scss';

type Props = {
  link: string;
  className?: string;
  children?: ReactNode;
};

const Link: FC<Props> = ({ link, children, className }) => (
  <a
    href={link}
    className={clsx(styles.link, className)}
    rel='noopener noreferrer'
    target='_blank'>
    {children}
  </a>
);

export default Link;
