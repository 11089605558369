import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import About from 'common/about/About';
import Feature from 'common/feature/Feature';
import List from 'common/list/List';

import styles from './Home.module.scss';

const Home: FC = () => {
  return (
    <div className={styles.home}>
      {/* <Helmet>
        <title>blog.jeanaica.com</title>
        <meta
          property='og:title'
          content='blog.jeanaica.com'
        />
        
        <meta
          property='og:image'
          content='https://blog.jeanaica.com/logo.png'
        />
        <meta
          name='publish_date'
          property='og:publish_date'
          content='2022-10-09T00:00:00-0000'
        />
        <meta
          property='og:url'
          content='https://blog.jeanaica.com'
        />
        <meta
          name='author'
          content='Jeanaica Suplido'
        />
      </Helmet> */}
      <div className={styles.main}>
        <h1>Website is under construction.</h1>
        {/* <Feature
          route='/article/hello-world'
          banner={`${process.env.PUBLIC_URL}/family.jpeg`}
          photoTitle='husband with yours truly'
          title='Hello World!'
          date='October 9, 2022'
          summary='When I was young I had
          this particular dream where I had many keys and I would be using them to
          open the door that is right in front of me, then the next door after
          that, then the next. I feel like this is one of those moments. I am a
          Christian who is a work in progress, I do have a lot to learn.'
        />
        <List /> */}
      </div>
      <About />
    </div>
  );
};

export default Home;
