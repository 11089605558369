import { FC, useEffect, useState } from 'react';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import Icon from '../icon/Icon';
import Logo from '../logo/Logo';
import Menu from '../menu/Menu';

import styles from './Header.module.scss';

const Header: FC = () => {
  const [expanded, setExpanded] = useState(true);
  const { width } = useWindowDimensions();

  const handleOnIconClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (width < 769) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [width]);

  useEffect(() => {
    const rootEl = document?.getElementById('root');

    if (rootEl && width < 769 && expanded) {
      rootEl.style.overflowY = 'hidden';
    } else {
      if (rootEl) {
        rootEl.style.overflowY = 'auto';
      }
    }
  }, [expanded, width]);

  return (
    <div className={styles.container}>
      <Logo />
      <button
        className={styles.icon}
        onClick={handleOnIconClick}>
        <Icon
          className={styles.menu}
          icon={expanded ? faClose : faBars}
        />
      </button>
      <Menu
        isExpanded={expanded}
        isMobile={width < 769}
      />
    </div>
  );
};

export default Header;
