import { FC, ReactNode } from 'react';

import styles from './Content.module.scss';

type Props = {
  children: ReactNode;
};

const Content: FC<Props> = ({ children }) => (
  <div className={styles.content}>
    <div className={styles.container}>{children}</div>
  </div>
);

export default Content;
