import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const socials = [
  {
    icon: faLink,
    link: 'https://jeanaica.com',
    label: 'jeanaica.com',
  },
  {
    icon: faInstagram,
    link: 'https://www.instagram.com/meeeowmeeeowmeeeow/',
    label: 'meeeowmeeeowmeeeow',
  },
  {
    icon: faLinkedinIn,
    link: 'https://www.linkedin.com/in/jeanaicasuplido/',
    label: 'jeanaicasuplido',
  },
];

export default socials;
