import { FC } from 'react';

import styles from './Logo.module.scss';

const Logo: FC = () => (
  <div className={styles.logo}>
    <h1 className='title'>jeanaica</h1>
    <span className={styles.caption}>tech. life. love.</span>
  </div>
);

export default Logo;
