import { FC, ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Icon from 'common/icon/Icon';
import Link from 'common/link/Link';

import styles from './IconLink.module.scss';

type Props = {
  icon: IconDefinition;
  link: string;
  className?: string;
  children?: ReactNode;
};

const IconLink: FC<Props> = ({ icon, link, className, children }) => (
  <div className={clsx(styles.iconLink, className)}>
    <Link
      link={link}
      className={styles.link}>
      <>
        <Icon
          icon={icon}
          className={styles.icon}
        />
        {children}
      </>
    </Link>
  </div>
);

export default IconLink;
